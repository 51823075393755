import React from "react";
import GlobalContext from "./GlobalContext";

type Props = {
  children: React.ReactNode;
};

const ContextWrapper = (props: Props) => {
  const [walletIsConnected, setWalletIsConnected] =
    React.useState<boolean>(false);
  const [connectedAddress, setConnectedAddress] = React.useState<string | null>(
    null
  );
  return (
    <GlobalContext.Provider
      value={{
        walletIsConnected,
        setWalletIsConnected,
        connectedAddress,
        setConnectedAddress,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default ContextWrapper;

import "../styles/globals.css";
import type { AppProps } from "next/app";
import Head from "next/head";
require("@solana/wallet-adapter-react-ui/styles.css");
import { SolanaConnectorProvider } from "utils/store/wallet-providers/solana";
import { EVMConnectorProvider } from "utils/store/wallet-providers/evm";
import ContextWrapper from "context/contextWrapper";

export default function App({ Component, pageProps }: AppProps) {
	return (
		<>
			<Head>
				<title>#hashmail</title>
			</Head>
			<ContextWrapper>
				<SolanaConnectorProvider>
					<EVMConnectorProvider>
						<Component {...pageProps} />
					</EVMConnectorProvider>
				</SolanaConnectorProvider>
			</ContextWrapper>
		</>
	);
}

import toast, { ToastOptions } from "react-hot-toast";

const defaultToastOptions = {
  background: "#444649",
  width: "max-content",
  maxWidth: "40%",
  color: "white",
  fontSize: "0.75rem",
};

export const PromiseToast = (
  resp: Promise<any>,
  loading: string,
  success: string,
  error: string,
  options?: ToastOptions
) => {
  toast.promise(
    resp,
    {
      loading,
      success,
      error,
    },
    {
      style: defaultToastOptions,
      ...options,
    }
  );
};
export const ErrorToast = (message: string) => {
  toast.error(message, {
    style: defaultToastOptions,
  });
};

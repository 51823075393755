import { createContext, useContext } from "react";
import { createConfig, configureChains, WagmiConfig } from "wagmi";
import { mainnet, optimism, polygon } from "viem/chains";

import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { publicProvider } from "wagmi/providers/public";
import { BinanceWalletConnector } from "utils/wallet-providers/binance-wallet";

interface EVMConnectorContextType {
  evmClient: unknown;
  setEVMClient: React.Dispatch<React.SetStateAction<unknown>>;
}
interface EVMConnectorProviderProps {
  children: React.ReactNode;
}
const EVMConnectorContext = createContext<EVMConnectorContextType>(
  {} as EVMConnectorContextType
);

const { chains, publicClient } = configureChains(
  [mainnet, optimism, polygon],
  [publicProvider()]
);

const connectors = () => {
  return [
    new MetaMaskConnector({
      chains,
      options: {
        shimDisconnect: true,
        UNSTABLE_shimOnConnectSelectAccount: true,
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "hashmail",
      },
    }),
    new BinanceWalletConnector({ chains }),

    new WalletConnectConnector({
      options: {
        projectId: "29894ac263546b6a468a313a9199d5f9",
        metadata: {
          name: "hashmail",
          description: "hashmail",
          url: "https://www.hashmail.dev/",
          icons: ["https://wagmi.sh/icon.png"],
        },
      },
    }),
  ];
};

const WagmiClient = createConfig({
  autoConnect: false,
  //@ts-ignore
  connectors,
  publicClient,
});

export const EVMConnectorProvider: React.FC<EVMConnectorProviderProps> = ({
  children,
}) => {
  return <WagmiConfig config={WagmiClient}>{children}</WagmiConfig>;
};

export const useEVMConnector = () => useContext(EVMConnectorContext);

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WalletAdapterNetwork, WalletError } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
  useLocalStorage,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider as ReactUIWalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  GlowWalletAdapter,
  LedgerWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";

import { ErrorToast } from "utils/notifications/toast";

// * INFO: Solana Network Configuration Context
interface NetworkConfigurationContextType {
  networkConfiguration: string;
  setNetworkConfiguration(networkConfiguration: string): void;
}
interface NetworkConfigurationProviderProps {
  children: React.ReactNode;
}
const NetworkConfigurationContext =
  createContext<NetworkConfigurationContextType>(
    {} as NetworkConfigurationContextType
  );

const NetworkConfigurationProvider: React.FC<
  NetworkConfigurationProviderProps
> = ({ children }) => {
  const [networkConfiguration, setNetworkConfiguration] = useLocalStorage(
    "network",
    "mainnet"
  );

  return (
    <NetworkConfigurationContext.Provider
      value={{ networkConfiguration, setNetworkConfiguration }}
    >
      {children}
    </NetworkConfigurationContext.Provider>
  );
};

interface NetworkConfigurationProviderProps {
  children: React.ReactNode;
}

// * INFO: Solana Wallet Connector Provider Context
interface SolanaConnectorProviderProps {
  children: React.ReactNode;
}
export const SolanaConnectorProvider: React.FC<
  SolanaConnectorProviderProps
> = ({ children }) => {
  const solNetwork = WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => clusterApiUrl(solNetwork), [solNetwork]);

  const [wallets, setWallet] = useState<any[]>([]);

  useEffect(() => {
    setWallet([
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      //@ts-ignore
      new SolflareWalletAdapter({ solNetwork }),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletExtensionWalletAdapter(),
      new SolletWalletAdapter(),
    ]);
  }, []);

  const onError = useCallback((error: WalletError) => {
    ErrorToast(error.message ? `${error.name}: ${error.message}` : error.name);
    console.error(error);
  }, []);

  return (
    // TODO: updates needed for updating and referencing endpoint: wallet adapter network
    <NetworkConfigurationProvider>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} onError={onError} autoConnect>
          <ReactUIWalletModalProvider>{children}</ReactUIWalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </NetworkConfigurationProvider>
  );
};

export default SolanaConnectorProvider;
